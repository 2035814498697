<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="80px"
    height="80px"
    viewBox="0 0 80 80"
  >
    <defs>
      <linearGradient
        x1="0.0203291262%"
        y1="49.9992693%"
        x2="100%"
        y2="49.9992693%"
        id="trrhnqzjum-linearGradient-1"
      >
        <stop stop-color="#EC008C" offset="0%"></stop>
        <stop stop-color="#AE108E" offset="25%"></stop>
        <stop stop-color="#771E90" offset="51%"></stop>
        <stop stop-color="#4F2891" offset="72%"></stop>
        <stop stop-color="#372F92" offset="89%"></stop>
        <stop stop-color="#2E3192" offset="100%"></stop>
      </linearGradient>
    </defs>
    <g
      id="trrhnqzjum-Elements---Logos"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M14.4000045,40.0003705 C14.4000063,29.6449114 20.6387938,20.3094029 30.2065766,16.347956 C39.7743593,12.3865091 50.786401,14.5794805 58.1066501,21.9040613 C65.4268992,29.228642 67.6133561,40.2419793 63.6462502,49.8074172 C59.6791444,59.3728551 50.3399472,65.6061194 39.9844902,65.5999955 C33.1964389,65.5999967 26.6866296,62.902418 21.8881724,58.1011214 C17.0897152,53.2998248 14.3959879,46.7884207 14.4000045,40.0003705 Z M40.0005846,0 C23.8223312,-1.60875249e-14 9.2370929,9.74554469 3.04594338,24.6923018 C-3.14520614,39.639059 0.276974062,56.8435206 11.7167267,68.2832733 C23.1564794,79.7230259 40.360941,83.1452061 55.3076982,76.9540566 C70.2544553,70.7629071 80,56.1776688 80,39.9994154 C80,17.9083483 62.0916517,1.07250166e-14 40.0005846,0 Z"
        id="trrhnqzjum-oh-icon"
        fill="url(#trrhnqzjum-linearGradient-1)"
        fill-rule="nonzero"
      ></path>
    </g>
  </svg>
</template>

<script>
export default {
  name: "OpenHealthCircleLogo",
};
</script>