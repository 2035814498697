<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="`${size}`"
    :height="`${size}`"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.125 24.375C4.125 35.5588 13.1912 44.625 24.375 44.625C35.5588 44.625 44.625 35.5588 44.625 24.375C44.625 13.1912 35.5588 4.125 24.375 4.125C13.1912 4.125 4.125 13.1912 4.125 24.375ZM24.375 47.625C11.5344 47.625 1.125 37.2156 1.125 24.375C1.125 11.5344 11.5344 1.125 24.375 1.125C37.2156 1.125 47.625 11.5344 47.625 24.375C47.625 37.2156 37.2156 47.625 24.375 47.625Z"
      fill="white"
      fill-opacity="0.3"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.1962 20.988C17.6104 21.5738 17.6104 22.5235 18.1962 23.1093C18.782 23.6951 19.7317 23.6951 20.3175 23.1093L22.8749 20.5519L22.8749 31.8195C22.8749 32.6479 23.5465 33.3195 24.3749 33.3195C25.2033 33.3195 25.8749 32.6479 25.8749 31.8195L25.8749 20.5519L28.4323 23.1093C29.0181 23.6951 29.9678 23.6951 30.5536 23.1093C31.1394 22.5235 31.1394 21.5738 30.5536 20.988L25.4356 15.8699C25.4302 15.8645 25.4248 15.8592 25.4193 15.8539C25.143 15.5859 24.7742 15.4343 24.3891 15.4306C24.3843 15.4306 24.3796 15.4306 24.3749 15.4306C24.3702 15.4306 24.3654 15.4306 24.3607 15.4306"
      fill="#EC008C"
    ></path>
  </svg>
</template>

<script>
export default {
  name: "CircledArrow",
  props: {
    size: {
      default: 48,
      type: Number
    }
  }
}
</script>