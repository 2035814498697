<style scoped>
.site-header {
  height: 105px;
  /* vertical-align: middle; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1440px;
  margin: 0 auto;
}
</style>

<template>
  <nav class="pt-1 site-header mt-4">
    <div class="ml-5">
      <a href="https://www.openhealthgroup.com/">
        <open-health-logo />
      </a>
    </div>
    <div v-if="isOnPlatform">
      <button class="btn mr-5 text-white bg-primary">Sign In</button>
    </div>
  </nav>
</template>

<script>
import OpenHealthLogo from "./OpenHealthLogo.vue";
export default {
  name: "SiteHeader",
  props: { isOnPlatform: { default: false, type: Boolean } },
  components: {
    OpenHealthLogo,
  },
};
</script>

