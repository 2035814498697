<style scoped>
footer {
  padding-bottom: 7.5rem;
  font-weight: 500;
  line-height: 1.5;
  color: #9ea6b0;
}

.flex-container {
  padding-top: 7.5rem;
  border-top: 2px solid #3a495d;
}

.footer-section {
  max-width: 33%;
}

.flex-container {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 750px) {

  .flex-container {
    padding-top: 0rem;
    flex-direction: column;
  }

  .footer-section {
    margin-top: 4rem !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}
</style>

<template>
  <footer class="footer bg-dark">
    <div class="max-width">
      <div class="ml-5 mr-5 flex-container text-center">
        <div class="footer-section">
          <open-health-circle-logo />
        </div>
        <div class="footer-section mt-3">
          © {{ year }} Copyright OPEN Health. All rights reserved.<br />
          OPEN Health is a
          <a class="text-primary" href="">registered</a> trademark.
        </div>
        <div class="footer-section">
          <a href="#app">
            <circled-arrow />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import OpenHealthCircleLogo from "./OpenHealthCircleLogo.vue";
import CircledArrow from "./CircledArrow.vue";
export default {
  components: {
    CircledArrow,
    OpenHealthCircleLogo,
  },
  name: "SiteFooter",
  data() {
    return { year: new Date().getFullYear() };
  },
};
</script>