import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

// {
//   path: '/example',
//   name: 'Example',
//   // route level code-splitting
//   // this generates a separate chunk (example.[hash].js) for this route
//   // which is lazy-loaded when the route is visited.
//   component: () => import(/* webpackChunkName: "example" */ '../views/Example.vue')
// }

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },

]

const router = new VueRouter({
  routes
})

export default router
