<style lang="scss">
@import "./scss/bootstrap-custom.scss";
@import "../node_modules/bootstrap/scss/bootstrap.scss";
</style>

<style>
@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway/Raleway-VariableFont_wght.ttf");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway/Raleway-VariableFont_wght.ttf");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Raleway";
  src: url("./assets/fonts/Raleway/Raleway-VariableFont_wght.ttf");
  font-weight: 500;
  font-style: normal;
}

body {
  /* font-size: 0.8rem; */
  font-family: "Raleway", sans-serif;
  background-image: linear-gradient(rgb(13, 33, 58, 0.4), rgb(13, 33, 58, 0.4)),
    url(~@/assets/homepage-cover-bg.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #0e213a;
  background-attachment: fixed;
  color: #fff;
}

@media screen and (min-width: 750px) and (orientation: landscape) {
  .max-width {
    max-width: 1440px;
    margin: 0 auto;
  }
}
@media screen and (max-width: 750px) {
  /* and (orientation: portrait) { */

  .p-5 {
    padding: 0 !important;
    padding-left: 1rem !important;
    padding-left: 0rem !important;
    padding-right: 1rem !important;
    padding-right: 0rem !important;
    padding-top: 2.5rem !important;
  }
  .m-5 {
    margin: 1rem !important;
    /* margin-left: 0 !important; */
  }
  .ml-5 {
    margin-left: 1rem !important;
    /* margin-left: 0 !important; */
  }
  .mr-5 {
    margin-right: 1rem !important;
  }
  .pl-5 {
    padding-left: 1rem !important;
    padding-left: 0rem !important;
  }
  .pr-5 {
    padding-right: 1rem !important;
    padding-right: 0rem !important;
  }
}
</style>

<template>
  <div id="app">
    <div id="nav">
      <site-header />
      <!-- <router-link to="/">Home</router-link> | -->
    </div>
    <router-view />
    <site-footer />
  </div>
</template>

<script>
import SiteHeader from "./components/SiteHeader.vue";
import SiteFooter from "./components/SiteFooter.vue";

export default {
  name: "App",
  components: { SiteHeader, SiteFooter },
};
</script>
