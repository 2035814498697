<template>
  <div class="pl-5 pr-5 pt-4 pb-5">
    <div class="max-width">
      <h2 class="ml-5 mb-5">
        Interactive Analytics <br />
        &amp; Communications
      </h2>
      <!-- <a href="#divider-container">
        <arrow class="m-5" />
      </a> -->
      <div id="divider-container" class="ml-1">
        <div class="ml-5 mb-2 mt-5 divider"></div>
      </div>
      <h3 class="ml-5 text-white pb-2 pt-4">Explore. Interact. Understand.</h3>
      <p class="text-white ml-5 mr-5 pt-1 mission-statement">
        Interactive tools to communicate and disseminate information to help
        your message be heard &mdash; and understood
      </p>
      <div class="m-5 max-width flex-container">
        <div class="app-card" v-for="app in apps" :key="app.header">
          <div class="shadow p-3 card bg-card">
            <div class="p-3">
              <h5 class="mb-4">{{ app.header }}</h5>
              <p>
                {{ app.paragraph }}
              </p>
            </div>
            <div class="app-card-footer">
              <button
                v-for="(button, index) in app.buttons"
                :key="index"
                :class="button.cssClasses"
              >
                <a
                  download="index.html"
                  :href="`${button.url}index.html`"
                  target="_self"
                  v-if="button.download"
                  >Download</a
                >
                <a v-else :href="button.url">{{ button.text }}</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Example from '@/components/Example.vue'

export default {
  name: "Home",

  data() {
    return {
      apps: [
        {
          header: "On and Offline Web Apps",
          paragraph:
            "Data come to life in interactive presentations where users can select and recalculate customized results.",
          buttons: [
            {
              url: "https://apps.openhealthgroup.com/mrsa/",
              text: "View Online",
              cssClasses: "btn ml-3 mb-3 text-white bg-primary",
            },
            {
              url: "https://apps.openhealthgroup.com/mrsa/",
              text: "Download",
              cssClasses: "btn m-1 mb-3 text-dark bg-white",
              download: true,
            },
          ],
        },
        {
          header: "Presentations with Integrated Analytics",
          paragraph:
            "Easily access information, perform on-the-fly calculations, and quickly generate custom leave-behinds.",
          buttons: [
            {
              url: "https://apps.openhealthgroup.com/interactive-presentation/",
              text: "View Online",
              cssClasses: "btn ml-3 mb-3 text-white bg-primary",
            },
          ],
        },
        {
          header: "R-based Web Apps",
          paragraph:
            "More complex calculations such as NMAs and sequencing models. Username/password access can be restricted to specified users.",
          buttons: [
            {
              url: "https://apps.openhealthgroup.com/nice-nma/#/",
              text: "View Online",
              cssClasses: "btn ml-3 mb-3 text-white bg-primary",
            },
          ],
        },
        {
          header: "On and Offline Economic Models",
          paragraph:
            "Polished presentation of model results where users can select and calculate their own scenarios of interest.",
          buttons: [
            {
              url: "https://apps.openhealthgroup.com/cea",
              text: "Cost Effectiveness",
              cssClasses: "btn ml-3 mb-3 text-white bg-primary",
            },
            {
              url: "https://apps.openhealthgroup.com/bim/#/",
              text: "Budget Impact",
              cssClasses: "btn ml-3 mb-3 text-white bg-primary",
            },
          ],
          // Example 1 ^, Example 2 Demo BIM
        },
        // {
        //   header: "Interactive Value Communication Compendia",
        //   paragraph:
        //     "Deliver the right message to the right stakeholders at the right time.",
        //   buttons: [
        //     {
        //       url: "https://apps.openhealthgroup.com/vcc/",
        //       text: "View Online",
        //       cssClasses: "btn ml-3 mb-3 text-white bg-primary",
        //     },
        //   ],
        // },
        {
          header: "QTWiST Benchmarking Tool",
          paragraph:
            "Interpret & contextualize your Q-TWiST results against published literature Q-TWiST values.",
          buttons: [
            {
              url: "https://apps.openhealthgroup.com/qtwist/",
              text: "View Online",
              cssClasses: "btn ml-3 mb-3 text-white bg-primary",
            },
          ],
        },
        {
          header: "Data Exploration Tools",
          paragraph:
            "Visually explore complex data sets to find the answers you need.",
          buttons: [
            {
              url: "https://apps.openhealthgroup.com/explorer",
              text: "Example 1",
              cssClasses: "btn ml-3 mb-3 text-white bg-primary",
            },
            {
              url: "https://apps.openhealthgroup.com/explorer-2022",
              text: "Example 2",
              cssClasses: "btn ml-3 mb-3 text-white bg-primary",
            },
          ],
        },
        // {
        //   header: "Enhanced Excel Dashboards",
        //   paragraph:
        //     "Leverage sophisticated macros directly from custom ribbon controls & easily adapt for PowerPoint presentations.",
        // },
      ],
    };
  },
};
</script>

<style scoped>
a {
  color: inherit;
}
.bg-card {
  background-color: rgba(13, 33, 58, 0.7);
  border-radius: 5px;
}
.card {
  min-height: 16.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.flex-container {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  justify-items: stretch;
}

.app-card {
  width: 33.33%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

p.mission-statement {
  font-size: 20px;
}

h2 {
  font-weight: 600;
  font-size: 56px !important;
}
h3 {
  font-weight: bold;
  font-size: 36px !important;
}
div.divider {
  /* margin-top: 40vh !important; */
  max-width: 80px;
  border: 1px solid #ec008c;
}
@media screen and (min-width: 750px) and (orientation: landscape) {
  .app-card:nth-child(3n + 2) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 750px) {
  /* and (orientation: portrait) { */
  .app-card {
    min-width: 100%;
  }
  .flex-container {
    flex-direction: column;
  }
  h2 {
    font-size: 42px !important;
  }
  h4 {
    padding-top: 1rem !important;
  }
}
</style>
